import React from 'react';

import { Template } from 'common/layout';

declare global {
  interface Window {
    zingEmbed: (url: string) => void;
  }
}

const ZfEmbedPage: React.FC = () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://saintsandstars.zingfit.com/static/embed.js';
    script.onload = () => {
      if (window.zingEmbed) window.zingEmbed('saintsandstars.zingfit.com');
    };

    document.head.appendChild(script);
  }, []);

  return (
    <Template>
      <div id="zingfit-embed" />
    </Template>
  );
};

export default ZfEmbedPage;
